import find from 'lodash/find';
import toNumber from 'lodash/toNumber';

import { Money } from 'data/graphql/types';

// Returns true if selectedPrice is lower than compareAtPrice, otherwise false
export const checkIfHasDiscount = (
  selectedPrices: Money[],
  compareAtPrices: Money[],
  currencyCode: string
) => {
  const selectedPrice = find(selectedPrices, { currencyCode });
  const compareAtPrice = find(compareAtPrices, { currencyCode });

  return (
    selectedPrice &&
    compareAtPrice &&
    toNumber(selectedPrice.amount) < toNumber(compareAtPrice.amount)
  );
};

export const checkIfHasDiscountWithNumbers = (
  price?: number | null,
  compareAtPrice?: number | null
): boolean => !!(price && compareAtPrice && price < compareAtPrice);

export const getDiscountPercentageMessage = (
  compareAtMax?: number,
  priceMinimum?: number,
  range?: boolean
) => {
  if (!compareAtMax || !priceMinimum || compareAtMax <= priceMinimum) {
    return;
  }
  return `${range ? 'up to ' : ''}${getDiscountPercentage(
    compareAtMax,
    priceMinimum
  )}% off`;
};

export const getDiscountAmount = (
  compareAtPrice: number,
  discountPrice: number
) => Math.floor(compareAtPrice - discountPrice);

export const getDiscountPercentage = (
  compareAtPrice: number,
  discountPrice: number
) => Math.round(((compareAtPrice - discountPrice) / compareAtPrice) * 100);

export const removeZeroDecimals = (price?: string) => {
  if (typeof price === 'string' && price.length > 3) {
    return price.replace('.00', '');
  }

  return price;
};
