import { gql } from '@apollo/client';

export interface IMarkdownModel {
  messageDuration: number;
  messageText: string;
}

export const GET_MARKDOWN_DATA = gql`
  query getMarkdownData {
    getSiteFurnitureData @client
  }
`;

export type GetMarkdownDataResponse = {
  getSiteFurnitureData: { markdown: IMarkdownModel };
};
