import { ReactElement } from 'react';

import MarkdownText, {
  MarkdownDisplayMode,
  MarkdownTextProps,
} from 'components/MarkdownText/MarkdownText';
import PromoMessaging from 'containers/ProductDetailPage/PromoMessaging/PromoMessaging';

import {
  MarkdownVariantDetails,
  usePromoEligible,
} from 'lib/hooks/promo/usePromoEligible';
import Logger from 'lib/utils/Logger';

export type MarkdownPromoContainerProps = {
  markdownDisplayMode?: MarkdownDisplayMode;
  productCategoryHierarchies: string[]; // an array of product category hierarchy strings (e.g ["Beauty > Skincare > Face > Face Serum"])
  productVariantDetails: MarkdownVariantDetails;
} & MarkdownTextProps;

/*
 * This component handles displaying the promo code, permanent, and temporary
 * markdown texts + modals, where precedence is Promo code > temp/perm markdown
 */
export const MarkdownPromoContainer = ({
  markdownDisplayMode = MarkdownDisplayMode.SUMMARY,
  productCategoryHierarchies,
  productVariantDetails,
}: MarkdownPromoContainerProps): ReactElement | null => {
  const offerDetails = productVariantDetails?.offerDetails;

  const {
    code,
    details,
    discount,
    error,
    isEligibleForPromo,
    loading,
    minSubtotal,
  } = usePromoEligible([
    {
      productCategoryHierarchy: productCategoryHierarchies,
      productDetails: productVariantDetails,
    },
  ]);

  if (error || loading) {
    return null;
  }

  let message;
  if (markdownDisplayMode === MarkdownDisplayMode.DETAIL) {
    message = `Get ${discount}% off with code ${code}`;
  } else if (markdownDisplayMode === MarkdownDisplayMode.CTA) {
    message = `Apply promo ${code} for ${discount}% off`;
    if (minSubtotal) {
      message += ` ($${minSubtotal} min. spend)`;
    }
  }

  try {
    if (isEligibleForPromo) {
      return (
        <div data-test-id="PromoMessaging-root">
          <PromoMessaging
            code={code}
            details={details}
            discount={discount}
            message={message}
            showModalAndButton={
              markdownDisplayMode === MarkdownDisplayMode.DETAIL
            }
          />
        </div>
      );
    }

    return (
      <MarkdownText
        markdownDisplayMode={markdownDisplayMode}
        offerDetails={offerDetails}
      />
    );
  } catch (error) {
    Logger.warn('Unable to render PromoMarkdown component', error);
    return null;
  }
};
