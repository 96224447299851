import { add, isBefore, parseISO } from 'date-fns';

import Logger from 'lib/utils/Logger';

import { OfferType } from 'types/generated/api';

export const shouldDisplayPermanentMarkdown = (
  startDate?: string | null,
  markdownDurationInDays?: number,
  markdownText?: string,
  offerType?: OfferType | null
) => {
  try {
    if (!startDate || !markdownDurationInDays || !markdownText || !offerType) {
      return false;
    }

    const currentDate = new Date().toISOString();
    const markdownEndDate = add(parseISO(startDate), {
      days: markdownDurationInDays,
    });

    return (
      OfferType.PERM_MARKDOWN === offerType &&
      isBefore(parseISO(currentDate), markdownEndDate)
    );
  } catch (error) {
    Logger.error(
      'Something went wrong running shouldDisplayPermanentMarkdown',
      error
    );
    return false;
  }
};

export const shouldDisplayTemporaryMarkdown = (
  endDate?: string | null,
  offerType?: OfferType | null
) => {
  try {
    if (!endDate || !offerType) {
      return false;
    }

    const currentDate = new Date();
    return (
      offerType === OfferType.TEMP_MARKDOWN &&
      isBefore(currentDate, parseISO(endDate))
    );
  } catch (error) {
    Logger.error(
      'Something went wrong running shouldDisplayTemporaryMarkdown',
      error
    );
    return false;
  }
};
