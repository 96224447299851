import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { trackEvent } from 'lib/analytics';
import AnalyticsEvent from 'lib/analytics/events';

type VisibilityChecker = (
  intersectionObserverEntry?: IntersectionObserverEntry
) => boolean;

export type TrackEventOnScreenConfig = {
  /**
   * Pass in extra logic to decide whether the monitored element is considered as visible.
   * For example, using this we can treat sections as not-visible until content is ready, or the section is
   * expanded from collapsed stated.
   */
  extraVisibilityChecker?: VisibilityChecker;
  /**
   * Only fires the event after the component is on screen after this given duration of milliseconds.
   * default: 1000
   */
  onScreenDurationMs?: number;
  /**
   * Number value of [0, 1]. To be counted as onScreen, at least this % of component need to be visible.
   * default: 0.75
   */
  threshold?: number;
  /**
   * true: Fire event each time the element is onScreen within the same page, like scroll out and back in.
   * false(default): Fire event only once.
   */
  trackForEveryAppearance?: boolean;
};

export const createElementMinimumHeightChecker = (
  minimumHeightInPx: number,
  wrappedVisibilityChecker?: VisibilityChecker
): VisibilityChecker => {
  return (intersectionObserverEntry?: IntersectionObserverEntry) =>
    (intersectionObserverEntry?.boundingClientRect?.height ?? -1) >
      minimumHeightInPx &&
    (!wrappedVisibilityChecker ||
      wrappedVisibilityChecker(intersectionObserverEntry));
};

// By default, monitored element must be visible by 75+% to be counted as onScreen
const DEFAULT_THRESHOLD = 0.75;
// By default, track the event after 1 second
const DEFAULT_ON_SCREEN_DURATION_MS = 1000;
/**
 * Sends a tracking event to Segment when the component is on screen.
 * This is great
 * to use when tracking "show <ComponentName>" analytical events
 *
 * However, if the component is removed from the VirtualDOM,
 * but then re-shown, then this tracking event will fire again
 * @param event - AnalyticsEvent to fire
 * @param eventData - optional object containing additional data to send
 * @param config - Configs to control when to trigger the tracking
 */
export const useTrackEventOnScreenDuration = (
  event: AnalyticsEvent,
  eventData?: Record<string, unknown>,
  config?: TrackEventOnScreenConfig
): ((node?: Element | null) => void) => {
  const onScreenDurationMs =
    config?.onScreenDurationMs || DEFAULT_ON_SCREEN_DURATION_MS;
  const threshold = config?.threshold || DEFAULT_THRESHOLD;
  const trackOnce = !config?.trackForEveryAppearance;

  const [didSendEvent, setDidSendEvent] = useState<boolean>(false);

  const [ref, inView, entry] = useInView({
    skip: didSendEvent && trackOnce,
    threshold,
  });
  const visiblePercentage = entry?.intersectionRatio;
  const isElementVisible =
    inView &&
    (!config?.extraVisibilityChecker ||
      (entry && config.extraVisibilityChecker(entry)));

  useEffect(() => {
    if (isElementVisible && (!trackOnce || !didSendEvent)) {
      const timeout = setTimeout(() => {
        trackEvent(event, {
          ...eventData,
          visible_percentage: visiblePercentage,
          visible_time: onScreenDurationMs,
        });
        setDidSendEvent(true);
      }, onScreenDurationMs);
      return () => clearTimeout(timeout);
    }
  }, [
    didSendEvent,
    isElementVisible,
    trackOnce,
    onScreenDurationMs,
    visiblePercentage,
    event,
    eventData,
  ]);

  return ref;
};
